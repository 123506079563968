<template>
	<div id="goodsTypeList">
		<page-type-list :is-shop="0"></page-type-list>
	</div>
</template>

<script>
	import pageTypeList from '@/components/layout/goods/page-type-list.vue'
	export default {
		components:{
			pageTypeList,
		},
		data() {
			return {
				
			}
		},
		mounted() {
		},
		
		methods: {
			
		}
	}
</script>

<style>

</style>
